import React, {useState} from "react";
import {Button, Input, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useAppContext} from "../../hooks";
import {postJsonAxiosService} from "../../services/callAxios";
import {toastService} from "../../services/toast.service";

const CloudTalkModal = (props) => {
    const {user} = useAppContext();
    const {provider, toggle} = props;
    const [apikey, setApikey] = useState("");
    const [errorApikey, setErrorApikey] = useState(false);
    const [apiId, setApiId] = useState("");
    const [errorApiId, setErrorApiId] = useState(false);

    const handleSubmit = async (values) => {
        if (!apikey) {
            setErrorApikey(true);
        }
        let provider = {
            providerId: apiId,
            accessToken: apikey,
            provider: "cloudTalk",
            service: "Dialer",
        }
        try {
            let response = await postJsonAxiosService("cloudTalk/webhook/validate", {provider: provider});

            if (response && response?.status === 200) {
                toastService.info("CloudTalk Integrated");
                // await new Promise(resolve => setTimeout(resolve, 2000));
                window.location.reload();

            } else {
                toggle();
               toastService.error("Invalid Api ID/Key");
            }
        } catch (e) {
            toastService.error("Error while updating integration");
        }
    };

    return (
        <div style={{padding: 20}}>
            <ModalHeader toggle={props.toggle}
                         style={{fontSize: 24, fontWeight: 600, border: "none"}}>
                <span>Add CloudTalk Integration</span>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="snippet-desc" style={{fontSize: 14, color: "#202124"}}>
                        <label>API ID</label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Enter API ID from CloudTalk account"
                               value={apiId}
                               onChange={(e) => {
                                   setApiId(e.target.value);
                                   setErrorApiId(false);
                               }}
                        />
                        {errorApikey ? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter API ID from your CloudTalk account
                            </div>
                        ) : null}
                        <label style={{paddingTop: 10}}>API Key </label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Enter API Key from CloudTalk account"
                               value={apikey}
                               onChange={(e) => {
                                   setApikey(e.target.value);
                                   setErrorApikey(false);
                               }}
                        />
                        {errorApikey ? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter API Key from your CloudTalk account
                            </div>
                        ) : null}
                    </div>
                    <div>
                    </div>
                </div>

            </ModalBody>
            <ModalFooter style={{border: "none", padding: "0px 0px 10px 30px"}}>
                <Button outline color="primary" onClick={props.toggle}>
                    Cancel
                </Button>{" "}
                <Button color="primary" onClick={handleSubmit}>
                    Authorize
                </Button>{" "}
            </ModalFooter>
        </div>
    )
};

export default CloudTalkModal;
